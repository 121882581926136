import { cn } from '@wla/lib/helpers/cn';

type WrapperProps = React.ComponentPropsWithRef<'div'> & {
  children: React.ReactNode;
  compact?: boolean;
  isCheckout?: boolean;
  fullWidth?: boolean;
  excludeWrapper?: boolean;
  excludeMobilePadding?: boolean;
};

export function Wrapper({
  children,
  className,
  compact,
  fullWidth,
  isCheckout,
  excludeWrapper,
  excludeMobilePadding,
  ...rest
}: WrapperProps) {
  return (
    <div
      className={
        !excludeWrapper
          ? cn(
              'mx-auto w-full px-3.5',
              {
                // The Figma max width is 1372px + 14px padding on each side
                'max-w-[1400px]': !compact && !fullWidth,
                'max-w-3xl': compact,
                'max-w-[874px] px-4': !compact && isCheckout,
                'px-0 sm:px-4': excludeMobilePadding,
              },
              className,
            )
          : className
      }
      {...rest}
    >
      {children}
    </div>
  );
}
