export type ErrorTrackingParams = {
  [key: string]: unknown;
};

export function convertToNewRelicAttributes(attr?: ErrorTrackingParams): {
  [key: string]: string | number | boolean;
} {
  if (!attr) return {};

  return Object.entries(attr).reduce((acc, [key, value]) => {
    let val = value;
    if (typeof value === 'object') {
      val = JSON.stringify(value);
    }

    return {
      ...acc,
      [key]: val,
    };
  }, {});
}

export async function trackError(error: unknown, params?: ErrorTrackingParams) {
  try {
    if (typeof window !== 'undefined') {
      const { trackClientError } = await import('./client');
      return await trackClientError(error, params);
    } else {
      const { trackServerError } = await import('./server');
      return trackServerError(error, params);
    }
  } catch (e) {
    console.error('Error during error tracking:', e);
    throw e;
  }
}
