'use client';
import { Alert } from '@wla/components/ui/alert';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { usePathname, useSearchParams } from 'next/navigation';
import { Button } from './button/button';

type ErrorMessageProps = {
  reset?: () => void;
  title?: string;
  description?: string;
  className?: string;
};

export function ErrorMessage({ reset, title, description, className }: ErrorMessageProps) {
  const t = useTranslations();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams.toString());

  function handleReset() {
    if (!params.has('resetTried')) {
      params.set('resetTried', 'true');
      history.replaceState({}, '', `${pathname}?${params.toString()}`);
      window.location.reload();
    } else {
      reset?.();
    }
  }

  return (
    <Alert className={cn('p-4 lg:p-8', className)} type="error">
      <h2 className="text-lg font-bold md:text-xl">{title || t('common.errors.an-error-occurred')}</h2>
      <p className="text-sm md:text-base">{description || t(`pdp.errors.something-went-wrong`)}</p>
      {reset && (
        <Button className="mt-4" onClick={handleReset}>
          {t(`common.errors.try-again`)}
        </Button>
      )}
    </Alert>
  );
}
